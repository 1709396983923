import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import Alert  from './components/Alert';

function App() {
  const [jwtToken, setJwtToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClassName, setAlertClassName] = useState("d-none");

  // const [ticking, setTicking] = useState(false);
  const [tickInterval, setTickInterval] = useState();

  const navigate = useNavigate();
  const location = useLocation(); //get current route location

  const logOut = () => {
    const requestOptions = {
      method: "GET",
      credentials: "include",
    }

    fetch(`${process.env.REACT_APP_BACKEND}/logout`, requestOptions)
    .catch(error => {
      console.log("error logging out", error);
    })
    .finally(() => {
      setJwtToken("");
      toggleRefresh(false);
    })
    
    navigate("/login");
  }

  const toggleRefresh = useCallback((status) => {
    console.log("clicked");

    if (status) {
      console.log("turning on ticking");
      let i  = setInterval(() => {

        const requestOptions = {
          method: "GET",
          credentials: "include",
        }

        fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            setJwtToken(data.access_token);
          }
        })
        .catch(error => {
          console.log("user is not logged in");
        })
      }, 600000); //600000: 600 seconds -> 10 minutes. Testing by set it to 1000 (1 second)
      setTickInterval(i);
      console.log("setting tick interval to", i);
    } else {
      console.log("turning off ticking");
      console.log("turning off tickInterval", tickInterval);
      setTickInterval(null);
      clearInterval(tickInterval);
    }
  }, [tickInterval])

  useEffect(() => {
    if (jwtToken === "") {
      const requestOptions = {
        method: "GET",
        credentials: "include",
      }

      //debug
      const API_URL = process.env.REACT_APP_BACKEND;
      console.log('Backend API URL:', API_URL);

      fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            setJwtToken(data.access_token);
            toggleRefresh(true);
          }
        })
        .catch(error => {
          console.log("user is not logged in", error);
        })
    }
  }, [jwtToken, toggleRefresh])  

  // Clear the alert message on route change
  useEffect(() => {
    setAlertMessage("");
    setAlertClassName("d-none");
  }, [location]);

  // const toggleRefresh = () => {
  //   console.log("clicked");

  //   if (!ticking) {
  //     console.log("turning on ticking");
  //     let i  = setInterval(() => {
  //       console.log("this will run every second");
  //     }, 1000);
  //     setTickInterval(i);
  //     console.log("setting tick interval to", i);
  //     setTicking(true);
  //   } else {
  //     console.log("turning off ticking");
  //     console.log("turning off tickInterval", tickInterval);
  //     setTickInterval(null);
  //     clearInterval(tickInterval);
  //     setTicking(false);
  //   }
  // }

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h1 className="mt-3">Go Watch a Movie!</h1>
        </div>
        <div className="col text-end">
          {jwtToken === ""
          ? <Link to="/login"><span className="badge bg-success">Login</span></Link>
          : <a href='#!'><span className="badge bg-danger" onClick={logOut}>Logout</span></a>
          }
        </div>
        <hr className="mb-3" />
      </div>

      <div className="row">
        <div className="col-md-2">
          <nav>
            <div className="list-group">
              <Link to="/" className="list-group-item list-group-item-action">Home</Link>
              <Link to="/movies" className="list-group-item list-group-item-action">Movies</Link>
              <Link to="/genres" className="list-group-item list-group-item-action">Genres</Link>
              {jwtToken !== "" &&
              <>
                <Link to="/admin/movies/0" className="list-group-item list-group-item-action">Add Movie</Link>
                <Link to="/manage-catalogue" className="list-group-item list-group-item-action">Manage Catalogue</Link>
                <Link to="/graphql" className="list-group-item list-group-item-action">GraphQL</Link>
              </>
              }
            </div>
          </nav>
        </div>
        <div className="col-md-10">
        {/* <a className="btn btn-outline-secondary" href="#!" onClick={toggleRefresh}>Toggle Ticking</a> */}
          <Alert message={alertMessage} className={alertClassName} />
          <Outlet context={{
            jwtToken, 
            setJwtToken, 
            setAlertMessage, 
            setAlertClassName,
            toggleRefresh,
          }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
