import { useState } from 'react'
import Input from './forms/Input'
import { useNavigate, useOutletContext } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { setJwtToken } = useOutletContext();
    const { setAlertMessage } = useOutletContext();
    const { setAlertClassName } = useOutletContext();
    const { toggleRefresh } = useOutletContext();

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log("email/pass", email, password);

        // if (email === 'admin@example.com') {
        //     setJwtToken('tlk_example_admin_token');
        //     setAlertMessage("");
        //     setAlertClassName("d-none");

        //     navigate("/");
        // } else {
        //     setAlertMessage("Invalid Credentials");
        //     setAlertClassName("alert-danger");
        // }

        // build the request payload

        let payload = {
            email: email,
            password: password,
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(payload),
        };
        // console.log(`${process.env.REACT_APP_BACKEND}/auth`)
        fetch(`${process.env.REACT_APP_BACKEND}/auth`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    setAlertMessage(data.message);
                    setAlertClassName("alert-danger");
                } else {
                    // console.log("access_token", data.access_token)
                    setJwtToken(data.access_token);
                    setAlertMessage("");
                    setAlertClassName("d-none");
                    toggleRefresh(true);

                    navigate("/");
                }
            })
            .catch(error => {
                setAlertClassName("alert-danger");
                setAlertMessage(error.message || "An error occurred");
            });
    }

    return (
        <div className='col-md-6 offset-md-3'>
            <h2>Login</h2>
            <hr />

            <form onSubmit={handleSubmit}>
                <Input
                    title="Email Address"
                    type="email"
                    className="form-control"
                    name="email"
                    autoComplete="email-new"
                    onChange={(event) => setEmail(event.target.value)}
                />

                <Input
                    title="Password"
                    type="password"
                    className="form-control"
                    name="password"
                    autoComplete="password-new"
                    onChange={(event) => setPassword(event.target.value)}
                />

                <hr />
                <input 
                    type="submit"
                    className='btn btn-primary'
                    value="Login"
                />
            </form>
        </div>
    )
}

export default Login;